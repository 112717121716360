import React from 'react'
import {navigateTo} from 'gatsby-link'
import Gallery from 'react-photo-gallery'

import Layout from '../components/layout'
import JumboBackground from '../components/jumbo-background'
import CardBox from '../components/card-box'
import Card from '../components/card'
import Button from '../components/button'

import Background from '../images/armadalest/n7.jpg'

const photos = [
  {
    src: require('../images/armadalest/2.jpg'),
    width: 960,
    height: 640
  },
  {
    src: require('../images/armadalest/3.jpg'),
    width: 960,
    height: 640
  },
  {
    src: require('../images/armadalest/6.jpg'),
    width: 960,
    height: 640
  },
  {
    src: require('../images/armadalest/7.jpg'),
    width: 960,
    height: 640
  },
  {
    src: require('../images/armadalest/n12.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/armadalest/n13.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/armadalest/n11.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/armadalest/n10.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/armadalest/n9.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/armadalest/n1.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/armadalest/n2.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/armadalest/n3.jpg'),
    width: 1920,
    height: 2800
  },
  {
    src: require('../images/armadalest/n4.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/armadalest/n5.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/armadalest/n6.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/armadalest/n7.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/armadalest/n8.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/armadalest/n14.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/armadalest/n15.jpg'),
    width: 1920,
    height: 1280
  }
  
]

const ArmadaleStreet = ({location}) => {
  return(
    <Layout path={location.pathname}>
      <JumboBackground
        background={Background}
      />
      <CardBox title="Armadale Street, Armadale" noSpacer center>
        <Card>
          <Gallery photos={photos}/>
          <br/>
          <Button dark onClick={() => navigateTo('/projects')}>Back to projects</Button>
        </Card>
      </CardBox>
    </Layout>
  )
}

export default ArmadaleStreet
