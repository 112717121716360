import React, {Component} from 'react'
import injectSheet from 'react-jss'


const styles = {
  background: {
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundAttachment: 'scroll',
		height: '100vh',
		width: '100%',
		minWidth: '240px',
		minHeight: '500px',
    '@media (max-width: 1024px)': {
      backgroundAttachment: 'scroll'
    },
    '@media screen and (-webkit-min-device-pixel-ratio:0)': {
      backgroundAttachment: 'fixed'
    }
	}
}

class JumboBackground extends Component {
  render() {
    const {classes, background } = this.props
    return(
      <div>
          <div className={classes.background} style={{backgroundImage: 'url(' + background + ')'}}/>
      </div>
    )
  }
}

export default injectSheet(styles)(JumboBackground)
